import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Formik } from 'formik';
import MainTemplate from 'templates/MainTemplate/MainTemplate';
import * as Yup from 'yup';
import Button from 'components/Button/Button';
import { Input } from 'components/Input/Input';
import { SectionTitle, Paragraph } from 'components/Typo';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StyledSectionTitle = styled(SectionTitle)`
  top: unset !important;
  align-self: unset !important;
  font-size: ${({ theme }) => theme.font.size.s};
  margin: 0;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 2rem;

  span {
    margin-top: 10px;
    display: block;
    font-weight: bold;
  }
`;

const StyledError = styled(StyledParagraph)`
  font-size: ${({ theme }) => theme.font.size.xs};
  position: absolute;
  bottom: -50px;
  z-index: 9999;
`;

const StyledInput = styled(Input)`
  background-color: transparent;
`;

const FormWrapper = styled.form`
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.tablet} {
    width: 70%;
  }

  ${({ theme }) => theme.mq.desktop} {
    width: 300px;
  }
`;

const mailingSchema = Yup.object().shape({
  email: Yup.string()
    .email('Nieprawidłowy format email')
    .required('Wpisz swój email, zanim wyślesz'),
});

const Newsletter = () => {
  const [isSubmitted, setSubmissionState] = useState(false);
  const [isAlreadyOnList, setIsAlreadyOnList] = useState(false);

  return (
    <MainTemplate isFooterVisible={false}>
      <Wrapper>
        {!isSubmitted && isAlreadyOnList && (
          <>
            <StyledSectionTitle>Błąd</StyledSectionTitle>
            <StyledParagraph>
              Twój adres już znajduje się na mojej liście.
              <span>
                Jeśli nie dostajesz maili ode mnie – sprawdź spam. Jeśli tam
                również nic nie ma, skontaktuj się ze mną.
              </span>
            </StyledParagraph>
          </>
        )}
        {!isSubmitted && !isAlreadyOnList && (
          <>
            <StyledSectionTitle>W kontakcie!</StyledSectionTitle>
            <StyledParagraph>
              Bądź na bieżąco z nowościami od Hello Roman!
              <span>
                Zapisz się i odbierz darmowego e-booka o dobrych praktykach w
                programowaniu!
              </span>
            </StyledParagraph>
          </>
        )}
        <Formik
          initialValues={{ email: '' }}
          validationSchema={mailingSchema}
          onSubmit={async ({ email }) => {
            try {
              await axios.post(
                'https://europe-west1-helloroman-mailing-list.cloudfunctions.net/mailing',
                { email }
              );
              setSubmissionState(true);
            } catch (e) {
              setSubmissionState(false);
              setIsAlreadyOnList(true);
            }
          }}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) =>
            isSubmitted ? (
              <>
                <StyledSectionTitle>Wielkie dzięki!</StyledSectionTitle>
                <StyledParagraph>
                  Wysłałem do Ciebie wiadomość z małą niespodzianką.
                </StyledParagraph>
              </>
            ) : (
              <FormWrapper onSubmit={handleSubmit}>
                <StyledInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  name="email"
                  type="email"
                  placeholder="Twój email"
                  autoComplete="off"
                />
                <Button
                  style={{ margin: '20px 0 0' }}
                  disabled={isSubmitting}
                  type="submit"
                >
                  wyślij
                </Button>
                {errors.email && touched.email ? (
                  <StyledError>{errors.email}</StyledError>
                ) : null}
              </FormWrapper>
            )
          }
        </Formik>
      </Wrapper>
    </MainTemplate>
  );
};

Newsletter.propTypes = {};

export default Newsletter;
